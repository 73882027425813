<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137 628.17">
        <rect class="id-d" x="5" y="5" width="1127" height="618.17" rx="55.21" ry="55.21" />
        <path class="id-e" d="M774.55,56.17c97.51-2.05,195.02-4.14,292.53-6.13,2.24,13.38,.86,27.34-3.94,40.02-8.76,23.13-6.65,29.16,1.76,50.88,.67,1.73,1.35,3.51,1.36,5.37,0,2.39-1.1,4.63-2.18,6.76-16.1,31.71-41.64,43.14-71.35,60.56-1.34,.79-2.74,1.63-3.56,2.95-.83,1.35-.95,3.01-1.04,4.59-1.43,24.45-1.95,45.26-11.26,67.95-9.3,22.66-21.89,43.78-31.7,66.2-3.57,8.15-7.14,16.34-9.49,24.93-3.22,11.79-4.05,24.08-4.87,36.28-1.55,23.13,.49,50.23-4.98,72.72-4.47,18.41-16.21,35.84-22.27,54-.72,2.09-1.44,4.31-.95,6.46,.35,1.55,1.3,2.88,2.23,4.17,5.39,7.5,10.78,15,16.17,22.5-88.39-7.97-125.54-5.65-140.1-.26-1.14,.42-3.82,1.48-6.36,.36-6.79-3,4.27-18.36,6.16-111.3,.4-19.68,3.59-39.34,2.4-59.05-.43-7.22-.25-20.99-4.92-26.99-3.9-5.02-1.35-4.86-6.89-6.32,.07,.02-14.32,6.83-14.14,3.36,1.38-26.06,9.51-50.13,5.1-76.74-2.13-12.86-6.56-25.91-3.75-38.64,1.82-8.24,6.54-15.57,8.86-23.68,5.56-19.47-2.55-41.76,12.09-58.29-5.86-9.89-11.71-19.79-17.57-29.68-1.43-2.42-2.89-4.91-3.31-7.68-.33-2.2,.01-4.44,.49-6.62,1.75-8.02,5.22-15.6,6.64-23.68,2.22-12.61-8.19-22.02-6.48-32.14,1.18-6.96,11.27-16.86,15.31-22.89Z" />
        <g>
            <path class="id-f" d="M173.68,549.25c0-4.64,3.36-8.32,8-8.32s8,3.68,8,8.32-3.36,8.32-8,8.32-8-3.68-8-8.32Z" />
            <path class="id-f" d="M187.28,504.6h14.56l5.44,22.72c1.44,5.52,2.4,10.88,3.84,16.48h.32c1.44-5.6,2.48-10.96,3.84-16.48l5.28-22.72h14.08l-15.36,52h-16.64l-15.36-52Z" />
            <path class="id-f" d="M238.8,504.6h33.6v11.52h-19.84v8.16h16.96v11.52h-16.96v9.28h20.64v11.52h-34.4v-52Z" />
            <path class="id-f" d="M283.28,504.6h19.68c11.2,0,20.8,3.84,20.8,16.8s-9.6,17.76-20.8,17.76h-5.92v17.44h-13.76v-52Zm18.72,23.68c5.44,0,8.32-2.4,8.32-6.88s-2.88-5.92-8.32-5.92h-4.96v12.8h4.96Zm-1.52,7.28l9.28-9.12,16.88,30.16h-15.36l-10.8-21.04Z" />
            <path class="id-f" d="M333.68,504.6h14.72l7.84,21.28c.96,2.88,1.76,6.08,2.72,9.12h.32c.96-3.04,1.76-6.24,2.72-9.12l7.52-21.28h14.72v52h-12.48v-15.36c0-5.28,1.12-13.36,1.76-18.56h-.32l-4.32,12.48-6.4,17.2h-7.36l-6.4-17.2-4.16-12.48h-.32c.64,5.2,1.76,13.28,1.76,18.56v15.36h-12.32v-52Z" />
            <path class="id-f" d="M393.52,530.37c0-17.12,9.76-26.72,24.16-26.72s24.16,9.68,24.16,26.72-9.76,27.2-24.16,27.2-24.16-10.08-24.16-27.2Zm34.24,0c0-9.44-3.84-14.88-10.08-14.88s-10.08,5.44-10.08,14.88,3.84,15.36,10.08,15.36,10.08-5.92,10.08-15.36Z" />
            <path class="id-f" d="M451.11,504.6h14.08l11.52,23.2,4.96,11.68h.32c-.64-5.6-1.76-13.44-1.76-19.84v-15.04h13.12v52h-14.08l-11.52-23.28-4.96-11.6h-.32c.64,5.92,1.76,13.44,1.76,19.84v15.04h-13.12v-52Z" />
            <path class="id-f" d="M514.79,516.12h-13.76v-11.52h41.28v11.52h-13.76v40.48h-13.76v-40.48Z" />
            <path class="id-f" d="M539.43,549.25c0-4.64,3.36-8.32,8-8.32s8,3.68,8,8.32-3.36,8.32-8,8.32-8-3.68-8-8.32Z" />
            <path class="id-f" d="M563.75,531c0-17.44,12-27.36,25.92-27.36,7.68,0,13.28,3.2,16.96,6.88l-7.2,8.8c-2.56-2.24-5.12-3.84-9.28-3.84-7.04,0-12.32,5.6-12.32,15.04s4.32,15.2,13.6,15.2c1.44,0,3.04-.32,4-.96v-7.2h-7.68v-11.2h19.84v24.8c-3.68,3.52-10.24,6.4-17.6,6.4-14.56,0-26.24-8.8-26.24-26.56Z" />
            <path class="id-f" d="M615.43,530.37c0-17.12,9.76-26.72,24.16-26.72s24.16,9.68,24.16,26.72-9.76,27.2-24.16,27.2-24.16-10.08-24.16-27.2Zm34.24,0c0-9.44-3.84-14.88-10.08-14.88s-10.08,5.44-10.08,14.88,3.84,15.36,10.08,15.36,10.08-5.92,10.08-15.36Z" />
            <path class="id-f" d="M665.67,504.6h14.56l5.44,22.72c1.44,5.52,2.4,10.88,3.84,16.48h.32c1.44-5.6,2.48-10.96,3.84-16.48l5.28-22.72h14.08l-15.36,52h-16.64l-15.36-52Z" />
        </g>
        <rect class="id-f" x="42" y="468" width="120" height="97" />
        <g>
            <path class="id-b" d="M51.59,478.31h20.64v78h-20.64v-78Z" />
            <path class="id-b" d="M89.51,478.31h23.04c23.76,0,40.08,11.04,40.08,38.64s-16.32,39.36-38.88,39.36h-24.24v-78Zm21.84,61.44c11.28,0,20.16-4.56,20.16-22.8s-8.88-22.08-20.16-22.08h-1.2v44.88h1.2Z" />
        </g>
        <line class="id-c" x1="64" y1="91" x2="691" y2="91" />
        <line class="id-c" x1="64" y1="174" x2="691" y2="174" />
        <line class="id-c" x1="64" y1="257" x2="333" y2="257" />
        <line class="id-c" x1="422" y1="257" x2="691" y2="257" />
        <line class="id-c" x1="64" y1="340" x2="333" y2="340" />
        <line class="id-c" x1="422" y1="340" x2="691" y2="340" />
        <line class="id-c" x1="64" y1="423" x2="333" y2="423" />
        <line class="id-c" x1="422" y1="423" x2="691" y2="423" />
    </svg>
</template>

<script>
export default {
    name: 'vdhLogo',
}
</script>

<style>
.id-b {
    fill: #fff;
}
.id-c {
    stroke-width: 20px;
}
.id-c,
.id-d,
.id-e {
    fill: none;
    stroke: #030;
    stroke-miterlimit: 10;
}
.id-f {
    fill: #030;
}
.id-d {
    stroke-width: 10px;
}
.id-e {
    stroke-width: 30px;
}
</style>
