<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to AHS Customer Portal Support!</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="image-row">
                            <font-awesome-icon class="help-icon" icon="file-upload" />
                            <font-awesome-icon class="help-icon" icon="money-bill-alt" />
                            <MabdLogo class="help-icon" />
                        </div>
                        <p class="heading-instruction">
                            Please check the common problems and solutions for customer portal login account before contacting the customer support.
                        </p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>

                <h2 class="faq-header">Unable to complete account setup</h2>

                <p class="faq-text">
                    While setting up account, if you see an error message saying
                    <b>"We found another account in our system matching your information”</b>, please send an email to the customer support as per the instructions provided at the bottom of this page.
                </p>

                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle"/></i>
                    <div class="noteText">
                        <p>
                            If you do not find an answer here, please send an email to Customer Support.
                        </p>
                        <p></p>
                        <p>
                            Send your requests to
                            <a class="email-link" href="mailto:AHS.BASUCustomerPortal@vermont.gov?subject=Assistance with my.vermont.gov benefits access">AHS.BASUCustomerPortal@vermont.gov</a>
                            from your email account that you use or are planning to use for the customer portal login.
                        </p>
                        <p>
                            To best serve you, please be sure to include the following information in your email:
                        </p>
                        <ol>
                            <li>Your full name (First name and Last name)</li>
                            <li>Phone number to reach you during business hours</li>
                            <li>
                                Description of the issue you have with your login account. Please include any error message you received.
                            </li>
                        </ol>
                        <p>
                            Please do not include any personal or identifying information (like Date of Birth, SSN or PIN) in your email. If necessary, you will be contacted by a State of Vermont customer portal support representative to assist you via phone.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MabdLogo from '../../assets/MabdLogo.vue'
import HelpGeneral from './HelpGeneral.vue'
export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: {
        MabdLogo,
        HelpGeneral,
    },
}
</script>

<style scoped>
@import './help.css';
</style>
