<template>
    <svg id="tims-a" width="100px" stroke="currentColor"  viewBox="-10 0 139.05 116.87" xmlns="http://www.w3.org/2000/svg">
        <rect class="tims-b" x="1" y="27.62" width="15.65" height="35.49" rx="3" ry="3" />
        <path fill="currentColor" class="tims-b"
            d="M91.71,82.64c-.89-.01-1.37,1-3.83,5.11-2.69,4.49-3,4.74-3.3,6.01-.21,.89-.79,3.61,.49,6.5,.26,.6,2.59,5.61,7.4,5.62,3.63,0,6.57-2.82,7.4-5.62,.75-2.5-.27-4.73-2.3-9.19-.68-1.5-1.39-2.62-2.81-4.85-2.13-3.36-2.67-3.57-3.06-3.57Z" />
        <path fill="currentColor" class="tims-b"
            d="M101.96,46.25v30.73h-21.45v-21.5h-16.93c-3.48,6.01-9.99,10.05-17.44,10.05s-13.96-4.04-17.44-10.05h-12.04v-20.23h12.03c2.58-4.45,6.81-7.82,11.85-9.27,1.78-.52,3.66-.79,5.6-.79,1.78,0,3.51,.23,5.16,.67,5.23,1.38,9.64,4.81,12.29,9.39h27.37c6.08,0,11,4.93,11,11Z" />
        <path fill="currentColor" class="tims-b"
            d="M80.64,8.82c.11,2.45-1.18,4.69-2.8,5.99-1.17,.94-3.94,2.54-14.56,.46-3.73-.73-8.42-1.64-11.98-2.8-.05-.02-.11-.03-.16-.05-1.22,1.54-3.1,2.53-5.22,2.53s-4.13-1.06-5.34-2.7c-.01,0-.03,.01-.04,.01-3.97,1.32-9.18,2.19-11.98,2.66-10.45,1.76-13.53-.28-14.55-1.1-1.39-1.1-2.71-3.03-2.81-5.35-.11-2.45,1.18-4.69,2.81-5.99,1.16-.94,3.93-2.54,14.55-.46,3.66,.72,8.23,1.61,11.76,2.73,1.17-1.85,3.24-3.08,5.6-3.08s4.55,1.3,5.7,3.25c3.93-1.25,8.93-2.09,11.66-2.55,10.45-1.77,13.53,.28,14.56,1.1,1.38,1.09,2.7,3.03,2.8,5.35Z" />
        <path fill="currentColor" class="tims-b"
            d="M51.3,12.47v13.39c-1.65-.44-3.38-.67-5.16-.67-1.94,0-3.82,.27-5.6,.79V12.26s.03-.01,.04-.01c1.21,1.64,3.15,2.7,5.34,2.7s4-.99,5.22-2.53c.05,.02,.11,.03,.16,.05Z" />
    </svg>
</template>
<style>
.tims-b {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 3px;
}
</style>

<script>
export default {
    name: 'timsLogo',
}
</script>
