<template>
    <svg id="LayerVdol" class="layer-vdol" width="65px" fill="currentColor" data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330.13 526.21">
        <path class="vdol-e" d="M148.85,149.87v55.15s-18.04-5.45-20.09-27.57,20.09-27.57,20.09-27.57Z" />
        <polygon class="vdol-e" points="159.32 149.87 159.32 209.34 182.3 214.21 182.3 149.87 159.32 149.87" />
        <path class="vdol-e" d="M192.26,216.6v-64.51s10.38,2.38,14.98,20.43v47.66l-14.98-3.57Z" />
        <path class="vdol-e" d="M129.49,321.11l18.21,3.06v61.11s-10.55-.17-18.21-24v-40.17Z" />
        <polygon class="vdol-e" points="158.78 328.26 158.78 388.34 182.09 388.34 182.09 332.68 158.78 328.26" />
        <path class="vdol-e" d="M192.47,336.94v49.02s14.64-7.66,14.64-24.51-14.64-24.51-14.64-24.51Z" />
        <path class="vdol-e"
            d="M12.68,364c-.28-.82,5.7-5.29,6.24-5.73,5.76-4.68,12.12-8.63,18.79-11.88,5.93-2.89,12.13-5.21,18.48-6.95,3.54-.97,7.43-2.21,11.11-2.45,4.41-.29,8.99,1.81,13.09,3.21,4.09,1.4,8.08,3.14,11.81,5.32,7.21,4.23,13.52,10.35,16.15,18.47,2.13,6.58,4.99,17.01,11.21,21.09,8.97,5.89,17.81,15.81,29.17,16.7v96.34s-5.32,13.62-20.98,0c-.33-.29,0-2.9,0-3.31v-79.41s-3.4-8.17-9.19,0v37.45s-43.91-6.47-63.32-25.87c-19.4-19.4-29.28-25.19-42.55-62.98Z" />
        <path class="vdol-e"
            d="M192.26,130.47V29.36c0-1.77,3.32-4.76,4.52-5.71,5.53-4.42,16.77-.16,18.46,6.61,.62,2.49,0,5.96,0,8.51v49.57c0,1.72-.88,6.37,0,7.91,0,0,4.09,7.15,8.85,0v-18.55s46.13,4.26,75.74,41.87c0,0,25.06,23.51,26.4,46.84,.06,1.09-.06,3.79-1.71,6.44-1.36,2.19-3.18,3.32-4.44,4.08-6.42,3.9-9.63,5.85-12.43,6.89-20.28,7.56-51.64,20.46-68.26,3.58-6.71-6.82-10.83-20.2-13.45-28.34-11.32-20.7-21.06-23.41-33.7-28.6Z" />
        <path class="vdol-e"
            d="M159.32,127.15V16.09c0-1.12,1.43-2.96,1.99-3.85,1.42-2.26,3.26-4.5,5.73-5.65,3.11-1.45,6.3-.33,8.84,1.71,1.27,1.02,2.39,2.23,3.42,3.5,.49,.61,.96,1.23,1.41,1.87,.36,.51,1.59,1.82,1.59,2.43V127.15h-22.98Z" />
        <path class="vdol-e"
            d="M262.98,247.66c-12.81-7.76-32.69-9.28-47.04-12.52-10.29-2.33-20.62-4.5-30.92-6.77-12.96-2.85-26.4-3.71-39.03-8.28-18.94-6.86-42.93-24.41-38.5-47.5,5.87-30.64,41.62-42.38,41.62-42.38V31.4c.07-.73,.4-4.88-2.47-7.66-1.67-1.62-3.69-2.06-5.19-2.38-1.33-.28-3.47-.74-5.96,.09-2.27,.75-3.62,2.14-4.68,3.23-2.23,2.31-3.34,4.86-3.91,6.72,0,24.1-.09,48.2,.04,72.3,0,1.35,0,2.75-.57,3.97-1.26,2.66-5.39,3.31-7.41,1.16-1.58-1.68-1.65-4.24-1.62-6.55,.02-1.44,.23-24.68,.11-24.68C11.49,91.66,13.28,190.72,13.28,190.72c4.09,52.85,36.26,79.91,36.26,79.91,26.81,22.47,108.26,36.51,108.26,36.51,77.62,6.89,70.21,58.21,70.21,58.21-14.04,47.23-69.45,37.28-69.45,37.28v83.5c0,8.94-3.31,25.59,5.04,31.2,4,2.68,9.62,2.68,13.62,0,7.7-5.15,5.08-16.92,5.08-24.94v-68.83c4.84-8.41,10.45-.02,10.47,0,2.56,3.83,.03,19.12,.02,23.7-.04,12.71-.08,25.42-.13,38.12-.01,3.7-.01,7.48,1.15,10.99s3.72,6.78,7.27,7.81c5.49,1.59,11.25-2.81,13.35-8.13,2.1-5.32,1.56-11.26,1.24-16.96-.48-8.64-.37-17.32,.33-25.94,.49-6.01,6.77-9.53,9.16-2.17,.2,.61,.35,1.3,.89,1.64,.42,.27,.96,.24,1.46,.19,23.35-2.14,42.2-17.55,60.5-30.79,0,0,37.28-26.81,36.26-87.83-.68-40.77-28.89-66.94-61.28-86.55Z" />
        <polyline class="vdol-c" points="48.26 177.43 48.26 191.21 48.26 219.81 102.89 219.81 102.89 243.81 63.57 243.81" />
        <line class="vdol-c" x1="85.02" y1="191.72" x2="48.26" y2="191.21" />
        <polyline class="vdol-c" points="120.26 243.81 201.96 263.23 231.57 263.23" />
        <polyline class="vdol-c" points="111.06 270.19 199.91 290.3 276 290.3" />
        <line class="vdol-c" x1="234.13" y1="316.34" x2="291.32" y2="316.34" />
        <line class="vdol-c" x1="244.85" y1="342.38" x2="291.32" y2="342.38" />
        <line class="vdol-c" x1="244.85" y1="377.62" x2="287.23" y2="377.62" />
        <line class="vdol-c" x1="234.13" y1="403.66" x2="276" y2="403.66" />
        <rect class="vdol-c" x="244.85" y="127.15" width="42.38" height="39.23" />
        <rect class="vdol-d" x="257.62" y="113.62" width="16.85" height="14.3" />
        <polygon class="vdol-d" points="94.47 135.58 68.43 161.63 55.66 150.39 82.21 123.84 94.47 135.58" />
        <polyline class="vdol-d" points="82.21 123.84 102.13 118.2 94.47 135.58" />
        <path class="vdol-b"
            d="M68.42,398.67c-31.32,2.72-25.87-32.34-25.87-32.34,0,0,15.66-3.74,25.87,8.85s0,23.49,0,23.49Z" />
        <path class="vdol-b"
            d="M55.79,366.01c1.01-2.3,3.46-6.9,8.55-9.59,2.73-1.44,5.07-1.72,9.21-2.22,5.27-.63,8.09-.97,10.07,.81,2.11,1.91,2.08,5.18,2.05,7.9-.04,3.88-1.31,6.79-1.79,7.8-3.28,6.9-10.24,10.79-10.32,10.7-.01-.01,.1-.15,.78-.76" />
        <path class="vdol-b"
            d="M76.34,392.78s11.6-11.16,19.94-5.48-2.47,20.19-2.47,20.19c0,0-8.57,7.89-20.67,6.62,0,0-3.45-12.39,3.2-21.33Z" />
        <line class="vdol-b" x1="72.25" y1="382.31" x2="72.25" y2="426.32" />
        <line class="vdol-b" x1="53.3" y1="378.3" x2="60" y2="386.32" />
        <line class="vdol-b" x1="89.32" y1="395.55" x2="82.38" y2="403.36" />
        <line class="vdol-b" x1="71.22" y1="368.4" x2="67.58" y2="371.81" />
    </svg>
</template>

<script>
export default {
    name: 'vdhLogo',
}
</script>

<style>
.vdol-b {
    stroke: #fcfcfc;
    stroke-width: 5px;
}

.vdol-b,
.vdol-c,
.vdol-d {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.vdol-c {
    stroke-width: 9px;
}

.vdol-c,
.vdol-d {
    stroke: #f9f9f9;
}

.vdol-e {
    
}

.vdol-d {
    stroke-width: 6px;
}

/* .clsvdol-1 {
    fill: inherit;
}

.clsvdol-2 {
    fill: none;
    stroke: #003300;
    stroke-width: 10px;
}


.clsvdol-4,
.clsvdol-7 {

    stroke: inherit;
}

.clsvdol-4,
.clsvdol-5,
.clsvdol-7 {
    stroke-miterlimit: 10;
}

.clsvdol-4 {
    stroke-width: 10px;
}

.clsvdol-3 {
    clip-path: url(#clip-path);
}


.clsvdol-4 {
    fill: #003300;

}

.clsvdol-5 {
    stroke: white;
    fill: inherit;
    stroke-width: 33px;
}


.clsvdol-6 {
    fill: inherit;
}


.clsvdol-7 {
    fill: inherit;
    stroke-width: 3px;
} */
</style> 
