<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to Tap Inventory Management System (TIMS) Support!</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="image-row">
                            <TimsLogo class="help-icon" />
                        </div>
                        <p class="heading-instruction">
                            Please check the common problems and solutions for myVermont login accounts before contacting the customer support.
                        </p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle"/></i>
                    <div class="noteText">
                        <p>
                            If you do not find an answer here, please send an email to Customer Support.
                        </p>
                        <p></p>
                        <p>
                            Send your requests to
                            <a class="email-link" href="mailto:AHS.LeadSchool@vermont.gov?subject=Assistance with my.vermont.gov TIMS access">AHS.LeadSchool@vermont.gov</a>
                            from your email account that you use or planning to use for the TIMS application.
                        </p>
                        <p>
                            Please do not include any personal or identifying information (like Date of Birth, SSN or PIN) in your email. If necessary, you will be contacted by a VDH support representative to assist you via phone.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TimsLogo from '../../assets/TimsLogo.vue'
import HelpGeneral from './HelpGeneral.vue'
export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: {
        TimsLogo,
        HelpGeneral,
    },
}
</script>

<style scoped>
@import './help.css';
</style>
