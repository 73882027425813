<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Town Clerks @ myVermont</h1>
                    <div class="sub-headings">
                        <div class="img-wrap">
                            <TownClerkLogo />
                        </div>
                        <p>
                            <b>Using one password across the Vital Records Issuance Management System (VRIMS),  Dept. of Liquor and Lottery (DLL), and more to follow...</b>
                        </p>
                        <p class="heading-instruction"></p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">Which username and password should town clerks use for which application?</h2>

                <p class="faq-text">
                    <b>A town clerk or assistant town clerk's password will be the same across any myVermont application.</b> This includes the Department of Liquor and Lottery licensing application at <a href="https:\\dllportal.vermont.gov">dllportal.vermont.gov</a> or VRIMS at the Vt. Dept. of Health (VDH).<br><br>
                    <b>For the Vital Records application, your username is typically firstname.lastname.</b> For other myVermont applications, your email will be your email address. In either case, the password will be the same. Resetting for one system will reset for all.<br /><br />As an example, consider Jane Chittenden, the Town Clerk for the fictional town of Ralston, Vermont. Jane's email address for Town Clerk purposes is jchittenden@ralstonvt.gov. Jane's credentials for myVermont connected systems might appear as follows:
                </p>

                <p><b>For Jane's Vital Records / VRIMS at VDH:</b></p>
                <table class="vdh_table">
                    <tbody class="section section-step">
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Username</td>
                            <td>jane.chittenden</td>
                        </tr>
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Password</td>
                            <td>Gr8clerk!!</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <p><b>For Jane's DLL licensing portal login:</b></p>
                <table class="vdh_table">
                    <tbody class="section section-step">
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Username</td>
                            <td>jchittenden@ralstonvt.gov</td>
                        </tr>
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Password</td>
                            <td>Gr8clerk!!<br />(always the same as VRIMS partner logins - changing one will change both)</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <p><b>For resetting Jane's password at my.vermont.gov:</b></p>
                <table class="vdh_table">
                    <tbody class="section section-step">
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Username</td>
                            <td>jchittenden@ralstonvt.gov</td>
                        </tr>
                        <tr>
                            <td class="credential-label" style="background-color: #003300; color: #ffffff">Password</td>
                            <td>Jane just needs to confirm that she can access her email on record and then remember her new password.<b>*</b></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <p><b>*Note: Users can only reset their own password once within 24 hours. Otherwise they need to contact support at the department whose application they are trying to access. See the Department of Liquor and Lottery <router-link to="/help-dll">DLL login assistance page</router-link> for assistance access those sites. For Vital Records, please see the VRIMS <a  target="_blank" rel="noopener" href="https://www.healthvermont.gov/stats/vital-records-population-data/information-town-and-city-clerks">information page for Town and City clerks.</a></b></p>

                <h2 class="faq-header">Why does myVermont matter for Town Clerks?</h2>

                <p class="faq-text">MY.VERMONT.GOV was implemented by the State in 2020 as part of an effort to move towards a single sign on environment for all citizen facing applications. This initiative, also known as myVermont, was motivated in part to make things more secure and easier for users. It has been successful with several programs, including for ESD economic benefits, healthcare, and Dept. of Liquor and Lottery Applications. By having a single account with the State, a user is more likely to retain their account information, keep it secure, and stay aware of any unusual activity.<br /></p>

                <div class="img-wrap img-wrap-faq">
                    <VdhLogo class="beer-icon" />
                    <font-awesome-icon class="help-icon beer-icon" icon="beer" />
                </div>
                <p class="clerk-apps-explain"><b>Look for these app icons at <router-link to="/">info.my.vermont.gov</router-link> for the DLL and VDH apps available to Town Clerks.</b></p>

                <p>Most Town Clerks and their staff would have first seen my.vermont.gov when it was made available in early 2021 as tool for self-service resetting of passwords for their VRIMS or other Vermont Department of Health access. In order to make this possible, this meant that any use of the same email address on any application using my.vermont.gov, would use the same password as is used for VDH applications. This can be somewhat confusing because some VDH applications have a non-email username. On the other hand, myVermont applications otherwise call for an email address for resetting passwords or entering other myVermont applications.</p> 

                <h2 class="faq-header">What if I need further assistance?</h2>

                <p class="faq-text">
                    Please first check the myVermont help page for the application your are trying to access. For VDH applications, that help page can be found <router-link to="/help-vdh-password">at this link.</router-link> For DLL applications, that help page can be found <router-link to="/help-dll">at this link.</router-link> If the instructions at those pages is not helpful, there is contact information at the bottom of each of those page for the respective department.<br /><br />
                    If you have an issue or concern with myVermont generally, and not particular to any application, please contact the Chief Technology Office at <a class="email-link" href="mailto:ads.mySupport@vermont.gov">ADS.mySupport@vermont.gov</a>. 
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import vdhAppsJson from '../features/vdhApps.json'
import VdhLogo from '../../assets/VdhLogo.vue'
import TownClerkLogo from '../../assets/TownClerkLogo.vue'


export default {
    name: 'vdhHelp',
    components: {
        VdhLogo,
        TownClerkLogo
    },
    data() {
        return {
            vdhApps: vdhAppsJson,
        }
    },
}
</script>
<style scoped>
@import './vdhHelp.css';
</style>
