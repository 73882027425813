<template v-cloak>
    <main>
        <div id="app-vue">
            <div id="headerWithContent">
                <div class="header-cust info-header-cust">
                    <div class="header-container">
                        <div class="info-header-text">
                            <div id="headerTextDiv">
                                <a class="c-header__link ds-u-color--white ds-u-font-size--small"
                                    href="https://www.vermont.gov" rel="noopener" target="_blank">VERMONT OFFICIAL STATE WEBSITE</a>
                            </div>
                        </div>
                        <div class="info-header-img-wrapper">
                            <a href="https://www.vermont.gov" target="_blank" rel="noopener">
                                <img class="info-header-img" src="./assets/logo.svg" alt="Vermont mountain logo" width="200"
                                    height="25" />
                            </a>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                    <div class="login-bar">
                        <div class="login-bar-container">
                            <div class="nav-log-wrapper" v-if="!activeUser" v-cloak>
                                <div class="nav-log" href="#" @click.prevent="login"><a>Login</a></div>
                            </div>
                            <div class="nav-log-wrapper" v-else v-cloak>
                                <span class="user-name"><a :href="oktaProfile">{{ userName
                                }}</a></span>
                                <div class="nav-log" href="#" @click.prevent="logout"><a>Logout</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="white-spacer"></div>
                <div class="test-warning-wrap">
                    <p class="test-warning" id="test-warning-home" v-if="testEnv" v-on:click="removeWarning">
                        You have reached a test page for testing and development purposes only. Information and applications
                        may not be submitted at this site. Contact the agency or department you are looking for in order to
                        obtain current links, or visit info.my.vermont.gov.
                        <b>CLICK TO REMOVE WARNING</b>
                    </p>
                </div>
                <!-- routes will be rendered here -->
                <router-view class="router-view" v-bind:auth="auth" />
            </div>
            <AppFooter class="app-footer"></AppFooter>
        </div>
        <div class="toolbar-container">
            <AccessibilityToolbar ref="toolbar" class="static"></AccessibilityToolbar>
        </div>
    </main>
</template>

<script>
import AppFooter from './components/features/AppFooter'
import AccessibilityToolbar from './components/features/Accessibility/AccessibilityToolbar.vue'

export default {
    name: 'app',
    components: {
        AppFooter,
        AccessibilityToolbar,
    },
    data() {
        return {
            activeUser: null,
            userName: '',
            authTime: null,
            testEnv: null,
            loading: false,
            timer: 0,
            currSeconds: 0,
            oktaProfile: process.env.VUE_APP_OKTA_DOMAIN + "/enduser/settings",
            auth: this.$auth,
        }
    },
    created() {
        this.displayWarning()
    },
    methods: {
        async login() {
            await this.$auth.signInWithRedirect()
        },
        removeWarning() {
            document.cookie = 'removeWarning=true'
            this.testEnv = false
        },
        displayWarning() {
            let hostName = window.location.hostname
            let hideCookie = this.getCookie('removeWarning')
            if (hostName !== 'info.my.vermont.gov' && hideCookie != 'true') {
                this.testEnv = true
            }
        },
        async refreshActiveUser() {
            this.loading = true

            this.$auth.getUser()
                .then((response) => {
                    this.activeUser = response
                    this.getDisplayName()
                }).catch((error) => console.log("error fetching user", error))
                .finally(() => {
                    this.loading = false // set loading to false when request finish
                }
            )
        },
        async logout() {
            await this.$auth.signOut()
            await this.isAuthenticated()
            // Navigate back to home
            this.$router.push({ path: '/' })
        },
        getDisplayName() {
            let userEmail = this.activeUser.preferred_username || ''
            let name = this.activeUser.name || ''
            if (name !== '') {
                this.userName = name
            } else {
                this.userName = userEmail
            }
        },
        resetTimer() {
            /* Clear the previous interval */
            clearInterval(this.timer)
            /* Reset the seconds of the timer */
            this.currSeconds = 0
            /* Set a new interval */
            this.timer = setInterval(this.startIdleTimer, 1000)
        },
        startIdleTimer() {
            /* Increment the timer seconds */

            this.currSeconds++
            if (this.currSeconds === 900 && this.activeUser) {
                // this.logoutOIDC
                this.logout()
            }
        },
        getCookie(cname) {
            let name = cname + '='
            let decodedCookie = decodeURIComponent(document.cookie)
            let ca = decodedCookie.split(';')
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length)
                }
            }
        },
    },
    watch: {
        authState: {
            handler(val) {
                if (val && val.isAuthenticated) {
                    this.refreshActiveUser();
                }
            },
            deep: true,
        }
    },
    mounted() {
        window.addEventListener('load', this.resetTimer)
        window.addEventListener('mousemove', this.resetTimer)
        window.addEventListener('mousedown', this.resetTimer)
        window.addEventListener('touchstart', this.resetTimer)
        window.addEventListener('click', this.resetTimer)
        window.addEventListener('keypress', this.resetTimer)
    },
    beforeUnmount() {
        this.resetTimer()
        window.removeEventListener('load', this.resetTimer)
        window.removeEventListener('mousemove', this.resetTimer)
        window.removeEventListener('mousedown', this.resetTimer)
        window.removeEventListener('touchstart', this.resetTimer)
        window.removeEventListener('click', this.resetTimer)
        window.removeEventListener('keypress', this.resetTimer)
    },
}
</script>

<style scoped>@import './app.css';</style>
