<template>
    <div>
        <div class="welcome-loading-box" v-if="loading">
            <Loading class="loading"></Loading>
            <div>loading</div>
        </div>
        <div v-if="!loadingStatus">
            <WelcomeVdhPartnerContent v-if="isVdh"></WelcomeVdhPartnerContent>
        </div>
    </div>
</template>

<script>
import WelcomeVdhPartnerContent from './WelcomeVdhPartnerContent'
import Loading from './features/LoadingWidget'

export default {
    name: 'welcome-user',
    components: {
        Loading,
        WelcomeVdhPartnerContent,
    },
    props: ['envLinks'],
    data() {
        return {
            loading: false,
            isVdh: false,
            isCustomer: false,
            isAdmin: false,
            newUser: false,
            newUserRedirect: '',
            dllEduNewUser: false,
            quickButtonCall: 'Go now...',
            quickButtonFa: '',
            quickBtnDesc: '',
            quickBtnApp: '',
            quickBtnRedirectCnt: 10,
            quickBtnRedirectImgPath: '',
            stopRedirect: false,
            timer: 0,
        }
    },
    created() {
        this.makeRequest()
    },
    methods: {
        makeRequest() {
            this.loading = true
            this.$auth.getUser()
                .then((response) => {
                    let groups = response.Groups
                    if (groups.find((a) => a.includes('AHS-VDH-OKTA'))) {
                        this.isVdh = true
                    } else {
                        this.isVdh = false
                    }
                    if (groups.find((a) => a.includes('Customer'))) {
                        this.isCustomer = true
                    } else {
                        this.isCustomer = false
                    }
                    if (groups.find((a) => a.includes('Admin'))) {
                        this.isAdmin = true
                    } else {
                        this.isAdmin = false
                    }
                }) // code to run on success
                .catch((error) => {
                    console.log(error)
                }) // code to run on error
                .finally(() => (this.loading = false)) // set loading to false when request finish
        },
        async newUserRedirectTimer() {
            /* Increment the timer seconds */
            this.quickBtnRedirectCnt--
            if (this.quickBtnRedirectCnt == 0) {
                window.location.href = this.newUserRedirect
            }
        },
        stopRedirectTime() {
            clearInterval(this.timer)
            this.stopRedirect = true
        },
    },
    computed: {
        getDescWithCurrentSeconds() {
            return this.quickBtnDesc.replace('{{secs}}', this.quickBtnRedirectCnt)
        },
        loadingStatus() {
            return this.loading
        },
    },
}
</script>

<style scoped>
@import './styles/Welcome.css';
</style>
