<template>
    <div class="infobody">
        <div class="white-header">
            <div class="tenforty-wrap">
                <router-link to="/" class="c-back__box">
                    <span class="c-back__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                            <path
                                d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z">
                            </path>
                        </svg>
                    </span>
                    <div>Return to info.my.vermont.gov</div>
                </router-link>
                <h1>Welcome to my.vermont.gov!</h1>
            </div>
        </div>
        <div class="welcome-cust-apps" v-cloak>
            <div class="welcome-top-block">
                <div class="small-btns">
                    <AppButtonSmall class="app-button app-button-sm admin-button"
                        v-bind:appText="'myVermont Account Management'" :appDesc="'Get help accessing myVermont apps.'"
                        routerLink="help-home" fontawesomePath="info-circle" btnCall="Get Help">
                    </AppButtonSmall>
                    <AppButtonSmall class="app-button app-button-sm admin-button"
                        v-bind:appText="'myVermont Account Management'" :appDesc="'Edit profile and myVermont login info.'"
                        :appLink="oktaProfile" v-bind:svgPath="'editUser'" v-bind:btnCall="'Update Account'">
                    </AppButtonSmall>
                </div>
                <AppButton v-if="isAdmin" class="app-button admin-button" v-bind:appText="'Okta Admin'"
                    :appDesc="'You are listed as an Okta administrator. Click here for Okta admin access.'"
                    :appLink="envLinks.linkAdmin" v-bind:svgPath="'oktaLogo'" :agencyDept="'CTO'"
                    v-bind:btnCall="'Admin Panel'" :helpLink="'help-home'"></AppButton>
                <AppButton class="app-button admin-button" v-bind:appText="'See all apps available on myVermont'"
                    :appDesc="'Click here to see the growing number of state services available on myVermont.'"
                    :routerLink="'/'" v-bind:imgPath="'momLogo.png'" :agencyDept="'SoV'" :helpLink="'help-home'"
                    v-bind:btnCall="'See All Apps'"></AppButton>
            </div>
            <div :id="'dept-agency-container-' + index" class="dept-agency-container" v-bind:key="index"
                v-for="(value, name, index) in sortedSearchResults">
                <p>{{ name }}</p>
                <ul class="two-col">
                    <li v-for="app in value" v-bind:key="app.title" class="external-benefits-link">
                        <AppButton class="app-button" :appText="app.title" :appDesc="app.appDesc"
                            :appLink="envLinks[app.envLinks]" :routerLink="app.routerLink"
                            :fontawesomePath="app.fontawesomePath" :svgPath="app.svgPath" :btnCall="app.btnCall"
                            :agencyDept="app.agencyCode" :helpLink="app.helpLink" :idProof="app.idProof"></AppButton>
                    </li>
                </ul>
            </div>
        </div>
        <WhatIsMyVermont></WhatIsMyVermont>
    </div>
</template>

<style scoped>
@import './styles/welcomeCustomer.css';
</style>

<script>
import WhatIsMyVermont from './features/WhatIsMy'
import AppButton from './features/AppButton'
import AppButtonSmall from './features/AppButtonSmall'
import allApps from './allApps.json'


export default {
    name: 'welcomeCustomer',
    props: ['admin', 'envLinks', 'groups'],
    components: {
        WhatIsMyVermont,
        AppButton,
        AppButtonSmall
    },
    data() {
        return {
            isAdmin: this.admin,
            oktaProfile: process.env.VUE_APP_OKTA_DOMAIN + "/enduser/settings",
            userGroups: this.groups
        }
    },
    computed: {
        sortedSearchResults: function () {
            let filteredResults = []
            let deptartmentAgencyList = {}

            allApps.forEach((app) => {
                if (typeof app.groups !== 'undefined') {
                    this.userGroups.forEach((group) => {
                        if (app.groups.includes(group)) {
                            filteredResults.push(app)
                        }
                    })
                }
            })


            filteredResults.forEach((app) => {
                if (!(app.agencyDept in deptartmentAgencyList)) {
                    deptartmentAgencyList[app.agencyDept] = []
                    deptartmentAgencyList[app.agencyDept].push(app)
                } else {
                    deptartmentAgencyList[app.agencyDept].push(app)
                }
            })

            return deptartmentAgencyList
        },
    }
}
</script>

<style>
.app-button-sm {
    width: 49% !important;
    height: 130px;
    margin: 5px auto 5px auto;
    align-items: flex-end;
}

.small-btns .app-button-sm:nth-child(1) {
    float: left;
}

.small-btns .app-button-sm:nth-child(2) {
    float: right;
}
</style>
