<template>
    <svg width="65px" fill="currentColor" id="LayerCdd" data-name="Layer Cdd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 911.25 577.71">
        <defs></defs>
        <path class="cls-1" d="M199,747c-.95-65.24-3.42-196.17-4.24-261.38l6.24,6.24C187.26,491.94,61.26,494,49.78,494,170.72,401.5,340.51,269.85,460.47,178.6l7.63-5.84,8.46,5.17,199.31,121.9L650.29,314c-.53-39.41-1.52-103-2.17-142.29,56.46-.74,123.78-2.13,180-2.41-1.86,77-4.5,155.45-8.23,232.36l-8.38-16.86c33.95,24.69,70.8,51.86,104.64,76.93L961,495l-55.11-1.52c-14.49-.42-43.28-1.19-57.74-1.67s-43.3-1.37-57.74-1.93l14.44-14.46c-2.36,89.69-5.42,182-10.83,271.57l-4,0c-3.19-43.67-5.88-91.8-8.1-135.71-2.41-48.3-4.63-101.58-6.34-149.79,31.46-.87,70.08-1.8,101.59-2.45l28.88-.54L895.9,490.27c-13.52-9.08-40.31-27.29-53.76-36.42-20.72-14.06-41.48-28.39-62.17-42.5.33-21.19.85-42.62,1.32-63.78.87-34.09,1.93-71.32,3.17-105.51.41-13.07,1.55-39.66,2-52.74L807,210.51c-40.34-.63-99-1-140-1.92L685.59,190c-.78,44.56-1.67,109.93-2.31,154.29C679.16,341.87,474,210,461.44,202.07l16.09-.67C364.51,283.82,193.41,409,78.78,492.08L74,477.56,201,479.39l6.33.09C206.41,547.7,204.15,678.77,203,747Z" transform="translate(-49.78 -169.3)" />
        <circle class="cls-2" cx="248.22" cy="413.7" r="33.5" />
        <ellipse class="cls-2" cx="365.72" cy="423.7" rx="46" ry="33.5" />
        <ellipse class="cls-2" cx="653.22" cy="414.2" rx="33.5" ry="49" />
        <circle class="cls-2" cx="517.22" cy="395.7" r="39.5" />
        <path class="cls-2" d="M242.5,721.5c9.75,3.5,26.22-52.37,51-51,24.29,1.34,33.79,56.42,44,54,9.15-2.17,14.5-49.49-6-71-2.54-2.66-6.89-6.42-6-8,2.41-4.28,38.83,15.55,41,12s-26.85-37.91-68-41c-31.84-2.39-60,14.74-59,19s33.17-1.73,35,5c1.16,4.24-11.17,8.21-21,19C233.63,681.3,235,718.79,242.5,721.5Z" transform="translate(-49.78 -169.3)" />
        <path class="cls-2" d="M390.36,737.94c4.74,1.38,13.19-53.34,36.14-56.44,26.13-3.53,53.6,62.26,58.86,59.44,3.51-1.87-4.82-33-19.86-63.44-6.86-13.88-11.67-20.64-9-24,7.23-9.08,56.24,23,57.86,20.44,1.89-3-53.95-63.11-98.86-47.44-31.4,11-46,54.93-42,58,3.79,2.94,30.31-27.18,34-24,1.65,1.42-2.63,8.31-6.14,15.44C386.87,705.46,386.85,736.92,390.36,737.94Z" transform="translate(-49.78 -169.3)" />
        <path class="cls-2" d="M515.3,721.93c9.33,3,28.45-70.12,53.2-68.43,24.15,1.65,32.27,73.06,41.8,71.43,7.09-1.21,13.54-42.56-6-71-8.95-13-20.89-19.76-19.8-21.43,2.27-3.48,51.16,30.07,54.8,25.43s-29.11-54.41-72.8-53.43c-31,.7-56.46,26.76-54.2,31.43,2.61,5.39,44.91-11.77,47.2-6.43,1.58,3.68-19,10.69-33.2,30.43C508,685.43,509.17,720,515.3,721.93Z" transform="translate(-49.78 -169.3)" />
        <path class="cls-2" d="M643.3,737.93c4.5,3.2,30.71-41.66,57.2-37.43,22.87,3.65,34.18,42,37.8,40.43,4.91-2.16-27-67.65-11.8-79.43,10.77-8.34,39.08,15,40.8,12.43,1.94-2.88-27-42.67-68-41-39.44,1.61-64.34,40.62-62.8,42.57s32.12-25.08,39-19C685.36,665.21,637.59,733.87,643.3,737.93Z" transform="translate(-49.78 -169.3)" />
        <path class="cls-2" d="M-132.5,289.5" transform="translate(-49.78 -169.3)" />
    </svg>
</template>
<script>
export default {
    name: 'CddProviderLogo',
}
</script>

            <style>
.cls-1,
.cls-2 {
    fill: inherit;
}
.cls-2 {
    stroke-miterlimit: 10;
    fill: inherit;
}
</style>

