<template>
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 415" fill="currentColor" width="49px" version="1.1">
        <ellipse class="b" cx="206" cy="207.5" rx="194" ry="195.5" />
        <path class="c"
            d="M100,371c-19.03-56.48-10.92-85.17,1-101,18.86-25.04,53.05-25.54,55-45,1.66-16.6-22.76-20.65-35-51-1.17-2.91-15.55-40.1,4-68,19.45-27.76,58.57-26.45,72-26,13.83,.46,49.92,1.67,70,30,16.1,22.72,15.1,53.26,6,74-10.46,23.84-30.91,32.85-27,42,4.23,9.9,28.9,2.01,45.5,18.5,6.84,6.79,9.67,15.11,11,21" />
        <path
            d="M273.24,324.08l15.92-3.5c3.69-.81,8.23-1.06,11.19-3.57,5.25-4.46,9.78-10.26,14.52-15.24l56.87-59.67,14.96-15.7c2.2-2.3,2.4-6.2,0-8.49l-10.05-9.58c-5.59-5.33-14.09,3.14-8.49,8.49l10.05,9.58v-8.49l-38.81,40.72-28.86,30.28c-5.86,6.15-12.18,12.09-17.69,18.56-2.25,2.65,.04-1.07,1.43-.3-.89-.49-5.6,1.23-6.53,1.44l-17.7,3.89c-7.54,1.66-4.35,13.23,3.19,11.57h0Z" />
        <path
            d="M277.35,319.81l2.57-15.13c.45-2.62,1.35-5.55,1.4-8.21,.05-2.89-2.28,2.05-1,1.12,1.9-1.37,3.53-3.71,5.14-5.39l10.02-10.51,56.07-58.83,14.96-15.7h-8.49l10.05,9.58c5.59,5.33,14.09-3.14,8.49-8.49l-10.05-9.58c-2.3-2.2-6.2-2.4-8.49,0-28.58,29.99-58.03,59.34-85.83,90.06-2.55,2.82-2.68,5.94-3.3,9.6l-3.11,18.29c-1.29,7.55,10.28,10.8,11.57,3.19h0Z" />
        <path class="c" d="M327.5,360.5c-2.24-9.55-4.88-19.57-8-30-3.18-10.63-6.56-20.64-10-30" />
    </svg>
</template>

<script>
export default {
    name: 'editUser',
}
</script>
<style>
.b {
    stroke-width: 24px;
}

.b,
.c {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
}

.c {
    stroke-width: 20px;
}</style>
