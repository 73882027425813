<template>
    <div class="what-is-wrap">
        <div class="why-btn-wrap">
            <b-button v-b-toggle.collapse-1 class="what-is-btn m-1"
                ><span class="bold-text"><a>What is MY.VERMONT.GOV?</a></span></b-button
            >
        </div>
        <b-collapse id="collapse-1">
            <b-card>
                <p class=""><b>MY.VERMONT.GOV</b> is a single-sign-on service built to make it easier for Vermonters to access critical applications and services. Please see the various applications available above. For your security, please make sure to logout of each application when you are done. If you have trouble logging in, or need more information, please see the help pages linked on each app tile above, or <b><router-link to="/help-home"> the general help page here.</router-link></b></p>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'whatIsMyVermont',
}
</script>

<style scoped>
.why-btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    color: white;
}

.why-btn-wrap p {
    color: white;
}

.what-is-btn {
    background-color: #457a7c !important;
    font-size: 1.25em;
}

.card {
    width: 90%;
    max-width: 1040px;
    margin: 0 auto 0 auto;
}
a {
    color: inherit;
}
</style>
