<template>
    <svg width="160px" id="Layer_1" fill="currentColor"   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 606.64 323.74">

        <path class="clsaot-1"
            d="M96.62,24.51c-.77.35-.54,2.48-.26,5.11.21,1.98.46,4.27,1.79,6.89.52,1.04,2.29,4.42,6.13,6.64,3.6,2.07,7.17,2.06,10.98,2.04,1.75,0,9.12-3.72,12.26-7.66,1.94-2.44,2.91-3.66,3.32-4.85,1.37-3.98-.05-7.84-1.02-10.47-.66-1.79-1.35-3.67-3.06-5.62-2.6-2.95-5.73-3.99-7.66-4.6-3.81-1.2-9.81-1.92-10.47-.26-.91,2.31,9.63,6.17,9.19,12-.18,2.42-2.21,4.47-4.09,6.38-2.09,2.12-3.63,3.69-5.62,3.83-5.45.39-9.66-10.28-11.49-9.45Z" />
        <path class="clsaot-1"
            d="M133.39,38.04l6.64,6.13,12,12.26c1.48,1.55,2.97,3.11,4.45,4.66-3.99.4-7.98.81-11.97,1.21-2.12.41-4.23.81-6.35,1.22-2.61-2.7-5.22-5.41-7.83-8.11l-6.64-6.64-4.34-3.57-7.91-3.32,12.26-9.45,4.09-2.55c1.87,2.72,3.74,5.45,5.62,8.17Z" />
        <polygon class="clsaot-2" stroke="currentColor" points="580.12 308.66 519.29 308.66 396.24 116.54 423.31 116.54 580.12 308.66" />
        <polygon class="clsaot-2" stroke="currentColor" points="474.89 308.66 420.06 308.66 357.43 116.54 367.97 116.54 474.89 308.66" />
        <polygon class="clsaot-2" stroke="currentColor" points="374.8 308.66 324.83 308.66 311.63 116.54 331.3 116.54 374.8 308.66" />
        <polygon class="clsaot-2" stroke="currentColor" points="291.9 116.54 279.83 308.66 226.55 308.66 271.15 116.54 291.9 116.54" />
        <polygon class="clsaot-2" stroke="currentColor" points="249.99 116.54 180.38 308.66 129.55 308.66 226.81 116.54 249.99 116.54" />
        <polygon class="clsaot-2" stroke="currentColor" points="199.45 116.54 83.17 308.66 25.78 308.66 172.24 116.54 199.45 116.54" />
        <polyline class="clsaot-4" stroke="currentColor" points="184.34 58.59 89.42 59.62 98.43 86.34 98.41 90.13 111.19 90.09" />
        <circle class="clsaot-4" stroke="currentColor" cx="130.55" cy="90.79" r="16.61" />
        <circle class="clsaot-4" stroke="currentColor" cx="230.28" cy="90.79" r="16.61" />
        <path class="clsaot-3" stroke="currentColor"
            d="M257.02,59.57c-.8-8.73-.74-10.4-2.38-11.57-3.2-2.31-7.12,1.41-14.64-.68-1.84-.51-4.11-1.14-6.13-3.06-2.68-2.56-2.2-4.84-4.43-11.91-1.84-5.87-3.06-9.59-6.47-12.26-1.77-1.39-3.36-1.89-6.13-2.72-5.11-1.55-10.07-1.97-17.02-1.7-9.83.38-14.75.56-16.68,2.38-3.63,3.42-2.84,10.35-1.13,24.07,1.47,11.79,3.32,16.97,2.15,26.65-1.05,8.72-3.29,10.64-4.09,11.23-5.26,3.94-12.36-1.4-17.02,2.72-3.08,2.72-3.7,8.33-1.7,10.33,1.56,1.56,4.01.21,10.21-.46,3.01-.32,7.46-.1,16.34.34,9.59.48,12.83,1.06,14.3-1.02,1.03-1.46-.08-2.43,0-7.15.1-6.17,2.09-10.6,2.72-11.91.64-1.34,2-3.86,4.43-6.47,3.67-3.95,7.76-5.89,10.21-6.81,1.88-.7,8.12-2.95,15.66-1.02,5.99,1.53,9.86,4.93,11.57,6.47,4.36,3.92,8.17,9.19,8.17,9.19,1.92,2.66,2.49,3.9,3.06,3.74,1.12-.3.18-5.34-1.02-18.38ZM193.89,48.68v-23.15h19.28l6.25,21.7-25.53,1.45Z" />
    </svg>

</template>

<script>
export default {
    name: 'aotRowIcon',
}

</script>

<style>
.clsaot-1 {
    stroke-width: 0px;
}

.clsaot-4 {
    fill: none;
    stroke-width: 12px;
}

.clsaot-4,
.clsaot-2,
.clsaot-3 {
    stroke-miterlimit: 10;
}

.clsaot-2 {
    stroke-width: 13px;
}

.clsaot-3 {
    stroke-width: 7px;
}
</style>
