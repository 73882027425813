<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to VDH Password Support</h1>
                    <div class="sub-headings">
                        <img src="../../assets/VDH_logo_green_horiz.png" />
                        <p>
                            <b>How can we help you?</b>
                        </p>
                        <p class="heading-instruction">
                            Please check the common problems and solutions below before contacting the program directly.
                        </p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">New Users</h2>

                <p class="faq-text">
                    If you are a new Health Department system user (including Immunization Registry, Electronic Death Registration System, Vital Records Issuance Management System, Electronic Birth Registration System, Hearing Screening Registry, Developmental Screening Registry, or Blood Lead Screening Registry), please wait for further instructions. Health Department staff will contact you with your account information, how to set it up, and how to log into the system you have permissions to use.
                </p>

                <h2 class="faq-header">Forgot your password</h2>

                <p class="faq-text">
                    If you have forgotten your password, you may reset it at any time (but only once within a 24 hour period). Please follow this link to start the reset process:
                    <a class="forgot-link" href="https://my.vermont.gov/signin/forgot-password">Reset password?</a>. Once at the reset password screen, please enter the email address you used when signing up for your State of Vermont account, and select submit. An email will be sent to the email you entered, with a link to reset your password. (Please note, with some email providers, including gmail, it may take up to 10-15 minutes for an email to appear.)
                    <br />
                    <br />Click on the link in the email which will open the password reset screen. The system will prompt you to enter your new password twice before submitting.
                </p>

                <h2 class="faq-header">Your password expired</h2>

                <p class="faq-text">
                    For security, we are required to have users reset their passwords every 180 days. State of Vermont will send reminder emails to the users when their password is about to expire. (Please note, the email will come from
                    <b class="noreply-link">noreply@vdh.vermont.gov</b>, which is an automatically generated message from the State of Vermont. Replies to that email address are not monitored or answered.)
                    <br />
                    <br />If your password has expired or is about to expire, you may reset it at any time. Please follow this link to start the reset process: <a class="forgot-link" href="https://my.vermont.gov/signin/forgot-password">Reset Password</a>.
                </p>

                <h2 class="faq-header">
                    Forgot the email address associated to your account
                </h2>

                <p class="faq-text">
                    Please follow this link,
                    <a class="forgot-link" href="https://my.vermont.gov/signin/forgot-password">Reset password?</a>, then enter your email address and submit. If the email address you entered is the email associated your State of Vermont login account, then you will receive an email with a link to reset your password.
                    <br />
                    <br />If you did not receive the reset password email within 10 minutes, you may try another email address or contact the program directly. Please use the list of programs sites provided on this page, for program contact information. Please note, with some email providers, including gmail, it may take up to 10-15 minutes for an email to appear.
                </p>

                <h2 class="faq-header">Your account got locked</h2>

                <p class="faq-text">
                    A user account will sometimes expire or get locked. An account will become locked after three unsuccessful log in attempts and will be locked for 30 minutes before the account is automatically unlocked.
                    <br />
                    <br />If you attempt to reset your password and you receive a “Password Reset Unavailable” email, please contact the State of Vermont program you have an account with. Please use the list of programs sites provided on this page, for program contact information.
                </p>

                <h2 class="faq-header">Browser Compatibility</h2>

                <p class="faq-text">If you are having any issues accessing a program listed below after resetting your password, the browser you use to view websites may not be compatible with VDH websites. Please see the list of compatible browsers for each program below:</p>
                <table class="vdh_table">
                    <thead>
                        <tr>
                            <th>Program</th>
                            <th>Browser Compatibility</th>
                        </tr>
                    </thead>
                    <tbody class="section section-step">
                        <tr>
                            <td>Immunization Registry (IMR)<hr />Vaccine Inventory Management System (VIMS)<hr />Hearing Screening Registry<hr />Developmental Screening Registry<hr />Blood Lead Screening</td>
                            <td class="brower-data">Edge, Firefox, and Chrome</td>
                        </tr>
                        <tr>
                            <td>Vital Records Issuance Management System (VRIMS)<hr />Electronic Death Registration System (EDRS)</td>
                            <td>Edge and Firefox</td>
                        </tr>
                        <tr>
                            <td>Electronic Birth Registration System (EBRS)</td>
                            <td>Edge, Firefox, and Chrome</td>
                        </tr>
                    </tbody>
                </table>

                <div class="vdh_noteAlt noteDesktop">
                    <div class="noteText vdh_noteText">
                        <h2 class>
                            <span><font-awesome-icon class="info-icon" :icon="'info-circle'" />&nbsp;&nbsp;&nbsp;VDH Program Contact and Login Page Information</span>
                        </h2>
                    </div>
                    <div style="width:100%">
                        <div v-for="vdhApp in vdhApps" :key="vdhApp.program">
                            <div class="vdh_program-section">
                                <div class="vdh_program-row">{{ vdhApp.program }}</div>
                                <div class="vdh_email-row" v-if="vdhApp.email">
                                    Contact: <a :href="'mailto:' + vdhApp.email + '?subject=my.vermont.gov VDH account assistance'">{{ vdhApp.email }}</a>
                                </div>
                                <div class="vdh_phone-row" v-if="vdhApp.phone">Phone: {{ vdhApp.phone }}</div>
                                <div class="vdh_website-row" v-if="vdhApp.website">Login Page: <a :href="vdhApp.website"> Click Here</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vdhAppsJson from '../features/vdhApps.json'

export default {
    name: 'vdhHelp',

    data() {
        return {
            vdhApps: vdhAppsJson,
        }
    },
}
</script>
<style scoped>
@import './vdhHelp.css';
</style>
