<template>
    <div class="info-footer">
        <div class="info-footer-div">
            <p>
                <span> Copyright © {{ viewYear }} State of Vermont. All rights reserved. </span>
                <span>
                    <a href="http://www.vermont.gov/portal/policies/accessibility.php" target="_blank" rel="noopener noreferrer">Accessibility Policy</a>
                    &nbsp;+&nbsp;
                    <a href="https://www.vermont.gov/policies/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'app-footer',
    data() {
        return {
            viewYear: new Date().getFullYear(),
        }
    },
}
</script>

<style scoped>
.info-footer p {
    margin-top: 10px;
    margin-bottom: 10px;
    background: #003300;
    background-color: #003300;
}

.info-footer {
    height: 35px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #003300;
    font-size: 14px;
    font-family: 'Public Sans';
    overflow: hidden;
    text-align: center;
}

.info-footer span,
.info-footer a {
    background: #003300;
    background-color: #003300;
    text-decoration: none;
    color: white;
    font-family: 'Public Sans', Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.info-footer-div {
    height: auto;
}
@media only screen and (max-width: 600px) {
    .info-footer {
        height: auto;
    }
}
</style>
