<template>
    <div class="info-home">
        <div class="infobody">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <h1>Welcome to your Health Department<br />account management landing page</h1>
                    <img class="welcome-vdh-img" src="../assets/VDH_logo_green_horiz.png" />

                    <div class="welcome-cust-apps" v-cloak>
                        <AppButton v-if="isAdmin" class="app-button" v-bind:appText="'Okta Admin'" v-bind:appDesc="'You are listed as an Okta administrator. Click here for Okta admin access.'" v-bind:appLink="'https://loginvt-admin.okta.com/admin/dashboard'" v-bind:imgPath="'oktaLogo.png'" v-bind:btnCall="'Admin Panel'"></AppButton>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <div class="index-para">
                    <p>You may now log into the Health Department system you are trying to access using the links below, but please note:</p>
                    <ul class="vdh-welcome-list">
                        <li class=""><b>Please login with your FirstName.Lastname username, not your email.</b></li>
                        <li class="">You are seeing this page because you have successfully reset your password. Once set, your password will expire in 180 days. SATIS passwords expire in 60 days.</li>
                        <li class="">If you have trouble logging in, changing your password, or need more information, please click on the “Password &amp; Account Management Help” button for additional support and contact information.</li>
                    </ul>
                    <div class="vdh-welcome-btn-wrap">
                        <router-link class="vdh-router-link" to="/help-vdh-password">
                            <button class="vdh-welcome-btn btn btn-success">
                                <div class="vdh-welcome-btn-text">
                                    <font-awesome-icon icon="info-circle" />
                                    <span>Password & Account Management Help</span>
                                </div>
                            </button>
                        </router-link>
                    </div>
                </div>
                <p>Below, please click on the name of the Health Department System you are trying to access to log in to your account.</p>
                <div class="user-group-box">
                    <ul class="vdh-linklist">
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/health-statistics-vital-records/registries/immunization">Immunization Registry (IMR)</a>
                        </li>
                        <li class="vdh-linkli vdh-linktext">IMR Phone: 1-888- 688-4667</li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/health-statistics-vital-records/registries/immunization">Vaccine Inventory Management System (VIMS)</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/health-statistics-vital-records/vital-records-population-data/vital-records-reporting-edrs">Electronic Death Registration System (EDRS)</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/stats/vital-records/information-town-and-city-clerks">Vital Records Issuance Management System (VRIMS)</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://nws.vdh.state.vt.us/vpn/index.html">Electronic Birth Registration System (EBRS)</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/family/health-care/hearing-health">Hearing Screening Registry</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/family/babies/vermonts-developmental-screening-registry">Developmental Screening Registry</a>
                        </li>
                        <li class="vdh-linkli">
                            <a href="https://www.healthvermont.gov/health-environment/chemicals-childrens-products/prevent-lead-poisoning-providers">Blood Lead Screening</a>
                        </li>
                    </ul>
                </div>
                <div class="satis-box alert alert-info">
                    <font-awesome-icon icon="info-circle" />
                    <div class="satis-text"><b>Substance Abuse Treatment Information System (SATIS)</b><br />Please return to SATIS Online to log in.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from './features/AppButton'

export default {
    name: 'welcomeVdh',
    props: ['admin'],
    components: {
        AppButton,
    },
    data() {
        return {
            isAdmin: this.admin,
        }
    },
}
</script>

<style scoped>
@import './styles/vdh.css';
</style>
