<template>
    <div :aria-label="'Tile holding button for ' + buttonText" class="btn-main draw-border">
        <div class="btn-content">
            <div class="img-wrap" >
                <font-awesome-icon class="svg" v-if="buttonFa" :icon="buttonFa" />
                <EditUser v-else-if="svgPath == 'editUser'" />
            </div>

        </div>
        <button target="_blank" :aria-label="'Button to go to app: ' + buttonText" v-if="!buttonPath" @click="goToLink(buttonLink)" type="button" class="btn btn-success">
            <a target="_blank">{{ buttonCall }}</a>
        </button>
        <router-link target="_blank" v-else class="btn btn-success" :to="'/' + buttonPath"
            ><a>{{ buttonCall }}</a></router-link
        >

    </div>
</template>

<script>



import EditUser from '../../assets/EditUser.vue' 
export default {
    name: 'appButton',
    props: ['appText', 'appLink', 'imgPath', 'fontawesomePath', 'svgPath', 'routerLink', 'appDesc', 'btnCall', 'iconWidth', 'agencyDept', 'helpLink', 'idProof'],
    components: {
        EditUser
    },
    data() {
        return {
            buttonText: this.appText,
            buttonLink: this.appLink,
            buttonImg: this.imgPath,
            buttonFa: this.fontawesomePath,
            buttonPath: this.routerLink,
            buttonDesc: this.appDesc,
            buttonCall: this.btnCall,
            mabd: this.mabdLogo,
            dllEdu: this.dllEduLogo,
            entity: this.agencyDept,
            btnHelp: '/' + this.helpLink,
        }
    },
    computed: {
        imgLink: function () {
            // this.imagelink
            return require(`../../assets/${this.buttonImg}`) //changed this
        },
    },
    methods: {
        goToLink: function (link) {
            // window.location.assign(link)
            window.open(link, "_blank");
        },
    },
}
</script>

<style scoped>
.draw-border {
    box-shadow: inset 0 0 0 2px #007834;
    color: #003300;
    transition: color 0.25s 0.0833333333s;
    position: relative;
    font-family: 'Public Sans', sans-serif;
}
.draw-border::before,
.draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
}
.draw-border::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
}
.draw-border::after {
    border-top-width: 4px;
    border-right-width: 4px;
}
.draw-border:hover {
    color: #0a8927;
    text-decoration: none !important;
}
.draw-border:hover::before,
.draw-border:hover::after {
    border-color: #0a8927;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
}
.draw-border:hover::before {
    transition-delay: 0s, 0s, 0.25s;
}
.draw-border:hover::after {
    transition-delay: 0s, 0.25s, 0s;
}

.btn-main {
    background-color: white;
    border: none;
    font: 700 'Public Sans', 'Roboto Slab', sans-serif;
    line-height: 1.5;
    padding: 1em 0.4em 0.3em 0.4em;
    letter-spacing: 0.05rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-end;
    text-align: center;
}

.btn {
    width: 100%;
    color: white;
    margin: auto;
    float: bottom;
}
a {
    color: inherit;
}
.btn-text-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 10px;
}

.btn-title {
    font-weight: 800;
}
.app-button-desc-text {
    font-weight: 400;
    font-family: 'Public Sans', 'Roboto Slab', sans-serif;
    margin: 5px 0 5px 0;
}

a,
a:hover {
    text-decoration: none !important;
}
.btn:focus {
    outline: 2px dotted #0a8927;
}
.btn-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* width: auto; */
    align-items: center;
    align-content: center;
}

.btn-success {
    background-color: #0a8927;
}
.img-wrap {
    width: 100%;
    align-self: center;
}


.img-wrap .svg {
    color: inherit;
    font-size: 50px;
}

.btn {
    font-size: inherit !important;
}

.overlay-ribbon-container {
    min-height: 2.3em;
    margin-top: 10px;
}
.overlay-ribbon {
    position: absolute;
    bottom: 1%;
    left: 0%;
    width: 60%;
    z-index: 99;
    margin-bottom: 5px;
    background: #457a7c;
    padding: 0 0 0 10px;
}

.ribbon-content {
    z-index: 100;
    position: relative;
    margin: 1px;
    text-decoration: underline;
    font-weight: 800;
}

.ribbon-content svg {
    font-size: 1.9em;
    padding-right: 5px;
}

.agency-label:hover {
    color: #3db5e6;
}

.agency-label {
    display: flex;
    flex-direction: row;
    font-size: 0.7em;
    margin: 5px -0.4em 5px -0.4em;
    width: 100%;
    text-align: left;
    padding: 0 1px 0 1px;
    color: white;
}

.agency-label div {
    margin-top: 4px;
    margin-left: 3px;
}

.id-logo {
    position: relative;
    float: right;
    right: 4%;
    max-width: 20%;
    top: 65%;
    background: white;
}
.id-logo-svg {
    width: 100%;
}

.sm-button-text {
    margin-bottom: 5px;
}
</style>
