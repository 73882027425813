<script>
import { onBeforeMount } from 'vue'
import { useAuth } from '@okta/okta-vue'
import { useRouter } from 'vue-router'
import { AuthSdkError } from '@okta/okta-auth-js'
 

export default {
  setup() {

    var error
    const $auth = useAuth();
    const router = useRouter();
 
    onBeforeMount(async () => {

      try {

        await $auth.handleLoginRedirect();
      } catch (e) {
        if (e instanceof AuthSdkError){
          console.log("error in callback!")
          console.log(error)
          router.push({ path: '/welcome' })
          console.log("Type Of", e)
        }
 

        const isInteractionRequiredError = $auth.isInteractionRequiredError || $auth.idx.isInteractionRequiredError;
        if (isInteractionRequiredError(e)) {
          const { onAuthResume, onAuthRequired } = $auth.options;
          const callbackFn = onAuthResume || onAuthRequired;
          if (callbackFn) {
            callbackFn($auth);
            return;
          }
        }
        error = e.toString();



      }


    });


  }
}
</script>