<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <div class="sub-headings">
                        <h1>myVermont</h1>
                        <div class="image-row">
                            <font-awesome-icon class="help-icon" icon="info-circle" />
                        </div>
                        <p><b>How can we help you?</b></p>
                        <p class="heading-instruction">Please check the common problems and solutions first. If you are unable to resolve your issue, links to specific application help pages are below. Contact information is available in each page.</p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <HelpGeneral :envLinks="envLinks" class="help-body"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>If you do not find an answer here, please review the myVermont help page for the department and app you are trying to use, and use the contact information on the following pages for additional assistance:</p>
                        <ul>
                            <li v-for="help in allHelp" v-bind:key="help.code">
                                <router-link :to="'/' + help.code"
                                    ><a>{{ help.desc }}</a></router-link
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpGeneral from './HelpGeneral'
import allHelp from '../allHelp.json'

export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: { HelpGeneral },
    data() {
        return {
            allHelp: allHelp,
        }
    },
    computed: {
        routePath(route) {
            return '/' + route
        },
    },
}
</script>

<style scoped>
@import './help.css';
</style>
