<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path
                                    d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z">
                                </path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Vermont Department of Labor<br />myVermont Login Support!</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="error-redirect-box" v-if="error == 'general'">
                            There was an issue logging you into claimant portal.
                            Please try again at <a class="email-link" href="https://claimantportal.labor.vermont.gov">claimantportal.labor.vermont.gov</a>. If that is unsuccessful, please reach out to support as provided below.
                        </div>
                        <div v-else class="image-row">
                            <img class="help-icon" src="../../assets/supportMoney.png" />
                            <p class="heading-instruction">Please read the common issues and solutions below before
                                contacting
                                our local job centers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">How to Register for VDOL Applications</h2>
                <p class="faq-text">A myVermont account will allow you to submit weekly claims with the Vermont
                    Department
                    of Labor ("VDOL") (<a class="email-link"
                        href="https://claimantportal.labor.vermont.gov">claimantportal.labor.vermont.gov</a>) as well as
                    other helpful state applications. If you don't already have one, one will be created for you when
                    you
                    file for unemployment. Look for an activation email from VDOL. If you did not receive one, please
                    use
                    the contact information below.</p>
                <h2 class="faq-header">Multifactor Authentication (MFA)</h2>
                <p class="faq-text">When you use your myVermont app on VDOL applications, multifactor authentication is
                    required. This means that in addition to your password, you will choose at least one of several
                    other
                    security options to access the application during each log in. This can be with a text message, a
                    phone
                    call, a physical security key or an authenticator app. Downloading an authenticator app to your
                    phone,
                    such as one of the following, is one of the more secure and easy to use options.</p>
                <ul>
                    <li>- Okta Verify on <a href="https://apps.apple.com/us/app/okta-verify/id490179405">iPhone</a> and
                        <a
                            href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=en_US&gl=US&pli=1">Android</a>
                    </li>
                    <li>- Google Authenticator on <a
                            href="https://apps.apple.com/au/app/google-authenticator/id388497605">iPhone</a> and <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
                    </li>
                    <li>- Microsoft Authenticator on <a
                            href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iPhone</a> and <a
                            href="https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=%20adjust_reftag%3Dc6f1p4ErudH2C%26utm_source%3DLanding%2BPage%2BOrganic%2B-%2Bapp%2Bstore%2Bbadges%26utm_campaign%3Dappstore_android">Android</a>
                    </li>
                </ul>
                <p class="faq-text">If you no longer have access to the device you used for MFA, you will need to
                    contact support as provided below.</p>
                <!-- <h2 class="faq-header">Completing Identity Proofing</h2>
                <p class="faq-text">Even after obtaining a myVermont account, some applications require identity proofing to complete. This includes the Department of Labor Application for filing your initial unemployment insurance claim application. Identity proofing requires that you provide personal information and answering verifying questions. If you do not feel comfortable answering these questions online, or you are encountering difficulties in completing identity proofing, please call the number at the bottom of this page to speak with a customer service representative to file your claim.</p> -->
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>If you encounter difficulties during the activation process, our Job Centers are set up to
                            provide claimants with direct assistance. You can call or visit a local Job Center Monday –
                            Thursday from 9:00am to 4:00pm or Fridays by appointment only. We encourage you to call your
                            local Job Center ahead to schedule an appointment. <a rel="noopener" target="_blank"
                                href="https://labor.vermont.gov/workforce-development/job-centers">Find A Local Job
                                Center |
                                Department of Labor (vermont.gov)"</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpGeneral from './HelpGeneral'

export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: { HelpGeneral },
    data() {
        return {
            error: ""
        }
    },
    created() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        this.error = urlParams.get('error')
        console.log(this.error)
    }
}
</script>

<style scoped>
@import './help.css';
</style>
