<template>
    <svg fill="currentColor" stroke="currentColor" width="175px" viewBox="0 0 1141.68 914.58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="m561.39,2.51c-51.37,46.33-102.73,92.67-154.1,139l5.3-2.2h-134.47c-32.2,0-64.41,0-96.61,0-17.52,0-35.14-.53-52.66,0-6.8.2-12.26,6.81-17.34,10.85-3.11,2.47-6.21,4.95-9.32,7.42s-8.42,5.37-10.49,8.94c-1.86,3.2-1.2,8.14-1.34,11.73-.17,4.3-.35,8.61-.52,12.91-1.37,34.1-2.75,68.21-4.12,102.31-.35,8.61-.69,17.22-1.04,25.83-.18,4.56,4.99,8.92,9.49,7.23,7.88-2.95,15.76-5.91,23.64-8.86,3.94-1.48,7.88-2.95,11.82-4.43,4.48-1.68,8.77-1.44,13.49-1.45,17.57-.02,35.14-.04,52.72-.07,34.81-.04,69.63-.09,104.44-.13s70.29-.09,105.43-.13c3.98,0,9.12.82,12.93-.28s7.12-5.36,9.98-7.94c6.67-6.01,13.34-12.03,20-18.04,13.09-11.81,26.18-23.61,39.27-35.42,29.39-26.51,58.78-53.02,88.16-79.52,7.18-6.48-3.45-17.06-10.61-10.61-51.37,46.33-102.73,92.67-154.1,139l5.3-2.2c-85.87.11-171.74.21-257.61.32-5.97,0-11.94.01-17.9.02-2.96,0-6-.21-8.95.01s-5.67,1.65-8.36,2.65c-11.21,4.2-22.42,8.41-33.64,12.61l9.49,7.23c2-49.67,4-99.33,6-149l-2.2,5.3c9.83-7.83,19.67-15.67,29.5-23.5l-5.3,2.2h137.46c32.26,0,64.53,0,96.79,0,16.65,0,33.51.8,50.14,0,1.87-.09,3.47-.54,5.05-1.61,6.93-4.7,13.05-11.67,19.28-17.29,13.09-11.81,26.18-23.61,39.27-35.42,25.68-23.17,51.37-46.33,77.05-69.5,6.42-5.79,12.84-11.58,19.26-17.37,7.18-6.48-3.45-17.06-10.61-10.61h0Z" style="fill: currentColor" />
        <path d="m630.69,323.81c-.26,26.93-17.14,52.06-42.81,61.14s-55.01,1.18-72.56-20.01-19.24-51.54-5.3-74.69,41.66-35.61,68.13-30.26c30.39,6.14,52.26,32.9,52.55,63.83.09,9.65,15.09,9.67,15,0-.32-33.48-21.19-63.41-52.57-75.14-31.03-11.6-67.84-1.7-88.83,23.91s-24.69,63.35-7.23,92.36c17.38,28.86,51.44,43.63,84.4,37.29,37.17-7.15,63.87-41,64.23-78.42.09-9.65-14.91-9.66-15,0Z" style="fill: currentColor" />
        <path d="m573.19,323.81v-44.86c0-9.65-15-9.67-15,0v44.86c0,9.65,15,9.67,15,0v-44.86c0-9.65-15-9.67-15,0v44.86c0,9.65,15,9.67,15,0Z" style="fill: currentColor" />
        <g>
            <line x1="603.19" y1="350.31" x2="562.69" y2="316.81" style="fill: #fff" />
            <path d="m608.5,345.01c-13.5-11.17-27-22.33-40.5-33.5-7.38-6.11-18.05,4.45-10.61,10.61,13.5,11.17,27,22.33,40.5,33.5,7.38,6.11,18.05-4.45,10.61-10.61h0Z" style="fill: currentColor" />
        </g>
        <path d="m184.74,357.31h172.71l-7.5-7.5v218.08l7.5-7.5h-172.71l7.5,7.5v-218.08c0-9.65-15-9.67-15,0v218.08c0,4.05,3.44,7.5,7.5,7.5h172.71c4.05,0,7.5-3.44,7.5-7.5v-218.08c0-4.05-3.44-7.5-7.5-7.5h-172.71c-9.65,0-9.67,15,0,15Z" style="fill: currentColor" />
        <path d="m184.74,431.33h172.71c9.65,0,9.67-15,0-15h-172.71c-9.65,0-9.67,15,0,15h0Z" style="fill: currentColor" />
        <path d="m184.74,508.97h172.71c9.65,0,9.67-15,0-15h-172.71c-9.65,0-9.67,15,0,15h0Z" style="fill: currentColor" />
        <path d="m278.6,567.9v-218.08c0-9.65-15-9.67-15,0v218.08c0,9.65,15,9.67,15,0h0Z" style="fill: currentColor" />
        <path d="m786.98,369.25h172.71l-7.5-7.5v218.08l7.5-7.5h-172.71l7.5,7.5v-218.08c0-9.65-15-9.67-15,0v218.08c0,4.05,3.44,7.5,7.5,7.5h172.71c4.05,0,7.5-3.44,7.5-7.5v-218.08c0-4.05-3.44-7.5-7.5-7.5h-172.71c-9.65,0-9.67,15,0,15Z" style="fill: currentColor" />
        <path d="m786.98,443.27h172.71c9.65,0,9.67-15,0-15h-172.71c-9.65,0-9.67,15,0,15h0Z" style="fill: currentColor" />
        <path d="m786.98,520.91h172.71c9.65,0,9.67-15,0-15h-172.71c-9.65,0-9.67,15,0,15h0Z" style="fill: currentColor" />
        <path d="m880.84,579.84v-218.08c0-9.65-15-9.67-15,0v218.08c0,9.65,15,9.67,15,0h0Z" style="fill: currentColor" />
        <path d="m446.69,721.31v-262.52l-7.5,7.5h253.58l-7.5-7.5v262.52c0,9.65,15,9.67,15,0v-262.52c0-4.05-3.44-7.5-7.5-7.5h-253.58c-4.05,0-7.5,3.44-7.5,7.5v262.52c0,9.65,15,9.67,15,0h0Z" style="fill: currentColor" />
        <circle cx="556.69" cy="557.81" r="38" style="fill: currentColor" />
        <path d="m509.69,734.81v-79c0-7.85-6.9-15.36-15-15s-15,6.59-15,15v79c0,7.85,6.9,15.36,15,15s15-6.59,15-15h0Z" style="fill: currentColor" />
        <path d="m552.44,895.81v-274c0-9.81-8.62-19.2-18.75-18.75s-18.75,8.24-18.75,18.75v274c0,9.81,8.62,19.2,18.75,18.75s18.75-8.24,18.75-18.75h0Z" style="fill: currentColor" />
        <path d="m599.94,895.81v-274c0-9.81-8.62-19.2-18.75-18.75s-18.75,8.24-18.75,18.75v274c0,9.81,8.62,19.2,18.75,18.75s18.75-8.24,18.75-18.75h0Z" style="fill: currentColor" />
        <path d="m646.69,598.81h-117c-7.85,0-15.36,6.9-15,15s6.59,15,15,15h117c7.85,0,15.36-6.9,15-15s-6.59-15-15-15h0Z" style="fill: currentColor" />
        <path d="m497.19,663.31c-.05-14.33,3.74-24.64,6.5-30.5,4.7-9.99,9.07-12.69,10.5-13.5,7.57-4.27,15.8-2.28,18.5-1.5" style="fill: none; stroke: currentColor; stroke-miterlimit: 10; stroke-width: 35px" />
        <path d="m556.19,760.31v-132" style="fill: none; stroke: currentColor; stroke-miterlimit: 10; stroke-width: 35px" />
        <path d="m625.96,546.55c1.73,19.26,3.46,38.52,5.19,57.78.7,7.79,6.4,15.39,15,15,7.53-.34,15.75-6.63,15-15-1.73-19.26-3.46-38.52-5.19-57.78-.7-7.79-6.4-15.39-15-15-7.53.34-15.75,6.63-15,15h0Z" style="fill: currentColor" />
        <path d="m1119.19,775.81c-10.09,0-20.18,0-30.27,0h-81.88c-40.25,0-80.5,0-120.75,0-49.14,0-98.28,0-147.42,0h-160.86c-53.63,0-107.27,0-160.9,0-49.58,0-99.16,0-148.74,0-41.4,0-82.8,0-124.21,0H58.13c-11.78,0-23.87.79-35.63,0-9.8-.66-10.28-14.21-.3-15,.7-.06,1.43,0,2.13,0,5.2-.02,10.39,0,15.59,0,23.52,0,47.03,0,70.55,0,37.5,0,75,0,112.5,0,47.42,0,94.83,0,142.25,0,52.75,0,105.49,0,158.24,0,53.98,0,107.96,0,161.94,0h153.4c43.81,0,87.62,0,131.44,0,32.03,0,64.06,0,96.08,0,16.42,0,32.85,0,49.27,0,1.1,0,2.22-.07,3.32,0,9.7.65,10.36,14.49.3,15s-9.66,15.49,0,15c10.17-.51,19.01-6.68,21.74-16.76,2.52-9.31-1.56-19.41-9.57-24.65-4.05-2.65-8.71-3.53-13.45-3.59s-9.34,0-14.01,0c-23.15,0-46.29,0-69.44,0-37.79,0-75.57,0-113.36,0-48.13,0-96.26,0-144.4,0-53.67,0-107.34,0-161.01,0-54.89,0-109.77,0-164.66,0h-154.9c-43.91,0-87.82,0-131.73,0h-95.15c-15.05,0-30.11-.09-45.16,0-1.15,0-2.29-.01-3.45.08-21.65,1.66-28.69,31.43-9.4,42.09,5.66,3.13,11.45,2.83,17.55,2.83h57.1c34.71,0,69.41,0,104.12,0,45.94,0,91.88,0,137.82,0,52.71,0,105.41,0,158.12,0,55,0,110,0,165,0,52.83,0,105.65,0,158.48,0h138.55c35.07,0,70.14,0,105.21,0h58.46c2.51,0,5.01,0,7.52,0,9.65,0,9.67-15,0-15Z" style="fill: currentColor" />
        <path d="m569.34,12.62c25.68,23.17,51.37,46.33,77.05,69.5,13.09,11.81,26.18,23.61,39.27,35.42,6.42,5.79,12.84,11.58,19.26,17.37,6.24,5.63,12.34,12.59,19.28,17.29,3.97,2.69,10.53,1.61,15.1,1.61,10.09,0,20.19,0,30.28,0,31.21,0,62.42,0,93.64,0,32.6,0,65.2,0,97.81,0,11.64,0,23.27,0,34.91,0,5.53,0,11.44.6,16.94,0,.25-.03.52,0,.77,0l-5.3-2.2c9.83,7.83,19.67,15.67,29.5,23.5l-2.2-5.3c2,49.67,4,99.33,6,149l9.49-7.23c-9.7-3.64-19.39-7.27-29.09-10.91-4.89-1.83-9.64-4.22-14.89-4.36-5.63-.15-11.28-.01-16.91-.02-21.55-.03-43.1-.05-64.65-.08-42.77-.05-85.54-.11-128.31-.16-24.2-.03-48.41-.06-72.61-.09l5.3,2.2c-51.37-46.33-102.73-92.67-154.1-139-7.15-6.45-17.79,4.12-10.61,10.61,26.18,23.61,52.35,47.22,78.53,70.84,13.09,11.81,26.18,23.61,39.27,35.42,6.42,5.79,12.84,11.58,19.26,17.38,3.21,2.9,6.42,5.79,9.63,8.69,2.93,2.65,6.16,6.69,9.82,8.23s8.94.65,12.84.66c4.64,0,9.28.01,13.93.02,8.62.01,17.24.02,25.86.03,17.57.02,35.14.04,52.72.07,35.14.04,70.29.09,105.43.13,19.56.02,39.12.05,58.68.07,4.92,0,10.01-.36,14.92.02,4.36.33,8.72,2.63,12.81,4.17,9.39,3.52,18.79,7.05,28.18,10.57,4.5,1.69,9.68-2.67,9.49-7.23-1.37-34.1-2.75-68.21-4.12-102.31-.35-8.61-.69-17.22-1.04-25.83-.16-3.97-.32-7.95-.48-11.92s.65-10.05-1.97-13.48c-2.26-2.96-6.21-5.24-9.13-7.56-3.36-2.68-6.73-5.36-10.09-8.04-4.42-3.52-10.32-10.47-16.21-10.85-14.44-.94-29.26,0-43.73,0h-93.69c-32,0-64,0-96,0-11.33,0-22.65,0-33.98,0-5.44,0-11.27-.6-16.69,0-.25.03-.52,0-.77,0l5.3,2.2c-51.37-46.33-102.73-92.67-154.1-139-7.15-6.45-17.79,4.12-10.61,10.61h0Z" style="fill: currentColor" />
    </svg>
</template>

<script>
export default {
    name: 'townClerkLogo',
}
</script>