<template>
    <div class="info-help">
        <div class="infobody-help">
            <div class="white-header">
                <div class="tenforty-wrap">
                    <router-link to="/" class="c-back__box">
                        <span class="c-back__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="14px">
                                <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        </span>
                        <div>Return to info.my.vermont.gov</div>
                    </router-link>
                    <h1>Welcome to the Department of Liquor and Lottery!<br />Portal and Storefront Support...</h1>
                    <div class="sub-headings">
                        <p><b>How can we help you?</b></p>
                        <div class="image-row">
                            <font-awesome-icon class="help-icon" icon="beer" />
                        </div>
                        <p class="heading-instruction">Please check the common problems and solutions for access to the DLL Portal or the DLL Storefront before contacting customer support.</p>
                    </div>
                </div>
            </div>
            <div class="tenforty-wrap">
                <h2 class="faq-header">How to Register for DLL Applications</h2>
                <p class="faq-text">A myVermont account will allow you to access both DLL licensing management functions on the DLL Portal (<a class="email-link" href="https://dllportal.vermont.gov">dllportal.vermont.gov</a>) and dll education products at the DLL Storefront (<a class="email-link" href="https://dllseminars.vermont.gov">dllseminars.vermont.gov</a>). If you do not have a myVermont account, just go to either the portal or storefront link, and click to register.</p>
                <HelpGeneral :envLinks="envLinks"></HelpGeneral>
                <div class="noteAlt">
                    <i><font-awesome-icon icon="info-circle" /></i>
                    <div class="noteText">
                        <p>If you do not find an answer here, please submit a request to the appropriate DLL support contact.</p>
                        <p>For Licensing assistance, email us at <a class="email-link" href="mailto:dll.licenseeportalinfo@vermont.gov">dll.licenseeportalinfo@vermont.gov</a> or call <a href="tel:802-828-2345">802-828-2345</a>, option 1.</p>
                        <p>For Storefront assistance, email us at <a class="email-link" href="mailto:DLL.DLCEduTeam@vermont.gov">DLL.DLCEduTeam@vermont.gov</a> or call <a href="tel:802-828-2339">802-828-2339</a>.</p>
                        <p>Please do not include any personal or identifying information (e.g. Date of Birth or SSN) when emailing DLL. If necessary, you will be contacted by a State of Vermont DLL support representative to assist you via phone.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpGeneral from './HelpGeneral'

export default {
    name: 'help-benefits',
    props: ['envLinks'],
    components: { HelpGeneral },
}
</script>

<style scoped>
@import './help.css';
</style>
