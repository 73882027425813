<template>
    <svg width="60px" viewBox="0 0 35 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path fill="currentColor" d="M17.499642,0 C20.6603735,5.29661145 27.6318804,9.89300639 35,10.9996979 C35,24.3934847 31.3531674,39.1850864 17.500358,43 C3.64540081,39.1850864 0,24.3934847 0,10.9996979 C7.29294933,10.3659937 14.3403424,5.29661145 17.499642,0 Z M19.6874553,14.0178742 L15.3125447,14.0178742 L15.3125447,20.0297065 L9.22244268,20.0297065 L9.22244268,24.3380494 L15.3125447,24.3380494 L15.3125447,30.3505866 L19.6874553,30.3505866 L19.6874553,24.3380494 L25.7775573,24.3380494 L25.7775573,20.0297065 L19.6874553,20.0297065 L19.6874553,14.0178742 Z" id="Shape"></path>
    </svg>
</template>

<script>
export default {
    name: 'mabdLogo',
}
</script>
