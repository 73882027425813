<template>
  <svg
    width="65px"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 308.4 291.85"
  >
    <path
      class="d"
      d="M188.12,260.63s-17.04,.27-21.03,9.85c-3.99,9.58,25.56,10.12,25.56,10.12,0,0,20.23-1.06,23.16-5.59,2.93-4.53,4.1-10.38-11.13-14.38,0,0-14.16-1.33-16.56,0Z"
    />
    <path
      class="c"
      d="M156.44,168.26l25.56,32.21c2.44,3.3,7.05,10.01,7.72,13.58,.71,3.75,.71,44.94,.71,44.94,0,0-1.77,7.77-3.1,8.04s7.11,3.46,9.81,0c0,0-2.62-6.12-2.09-8.52s.27-38.33,.27-38.33c0,0,.8-10.38,4.79-14.38s31.29-41.21,31.29-41.21c-34.73,24.01-77.48,0-77.48,0l2.54,3.68Z"
    />
    <path
      class="d"
      d="M156.85,164.59c8.34-1.55,18.1-2.76,29-3,16.52-.37,30.79,1.59,42,4-10.16-1.59-21.92-2.79-35-3-13.46-.21-25.57,.67-36,2Z"
    />
    <path
      class="d"
      d="M69.02,86.41c88-41.62,171.57,0,171.57,0l64.34-28.38L154.62,2.11,3.02,58.03l66,28.38Z"
    />
    <path
      class="d"
      d="M71.02,153.26c86-36.41,169.57,0,169.57,0v-62.23c-102.43-36-169.57,0-169.57,0v62.23Z"
    />
    <rect class="d" x="28.17" y="75.03" width="5.11" height="39.12" />
    <circle class="d" cx="31.02" cy="124.03" r="8" />
    <path
      class="d"
      d="M27.02,134.03s-10,35-9,43,7-18,7-18l-2,17.04,5.15-14.64,2.85,15.32,6-16.72,2,17.06s7-9.06,5-16.06-8.02-27-8.02-27h-8.98Z"
    />
    <path
      class="b"
      d="M96.92,164.57l-4.61,8.41v108.07s20.37,14.16,51.57,0v-104.81l-3.81-11.67s-23.07,9.5-43.15,0Z"
    />
    <path
      class="e"
      d="M93.47,281.89c3.11,2.5,4.2,3.42,4.17,3.45,0,0,0,.04-.1,.08-.81,.27,19.12,8.62,37.24,.92,1.96-1.61,3.62-2.81,4.82-3.63,1.08-.74,2.25-1.49,2.17-1.63-.08-.14-1.37,.43-1.63,.54-1.8,.78-6.26,2.18-17.37,4.89-3.24,.35-10.41,1.06-19.27-1.09-4.23-1.02-7.64-2.4-10.04-3.53Z"
    />
    <ellipse class="b" cx="117.68" cy="163.75" rx="21.3" ry="5.16" />
  </svg>
</template>
<script>
export default {
  name: "dllEdu",
};
</script>
